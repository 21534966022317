h1,
h2,
.h1,
.h2,
h3,
h5,
.h3,
.h5,
h4,
h6,
.h4,
.h6,
p {
  color: $charcoal;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

h1,
h2,
.h1,
.h2 {
  font-family: $serif;
}

h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  font-family: $sans-serif;
  font-weight: 400;
}

h1 {
  font-size: 30px;
}

h2 {
  font-size: 24px;
}

h3 {
  font-size: 20px;
}

h4 {
  font-size: 18px;
  color: $darkGray;
}

p {
	margin: 15px 0;
}

p,
a,
.post,
.entry {
  font-family: $sans-serif;
  line-height: normal;
}

a {
  color: $safety-orange-blaze-orange;
  text-decoration: none;
	font-family: $serif;
	cursor: pointer;
  &:hover, &:active {
    color: $honey-yellow;
  }
}

ul, ol {
	margin: 15px 0;
	padding-left: 30px;
	font-family: $sans-serif;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

ol ul,
ul ol,
ul ul,
ol ol {
  margin: 0;
}

ul ul,
ol ul {
  list-style-type: circle;
}

em,
i {
  font-style: italic;
}

strong,
b {
  font-weight: 700;
}
.text-center,
.center {
  text-align: center;
}
.text-right,
.right {
  text-align: right;
}

.white {
  color: $white;
}
.serif {
  font-family: $serif;
}
.sans-serif {
  font-family: $sans-serif;
}

.home-link {
  color: $safety-orange-blaze-orange;
  &:hover {
    color: $honey-yellow;
  }
}

@media #{$breakpoint-sm} {
  h1 {
    font-size: 7vw;
  }
  h2 {
    font-size: 6.25vw;
  }
  h3, h4, h5, h6, p {
    font-size: 5vw;
  }
}

.primary {
  color: $blue;
}
.secondary {
  color: $lite-blue;
}
.tertiary {
  color: $green;
}

.serif {
   font-family: $serif;
}
.sans-serif {
   font-family: $sans-serif;
   font-weight: 400;
}
.sans-serif-bold,
.bold {
   font-family: $sans-serif;
   font-weight: 700;
}