//
// IMPORTS
//

@import "variables";
@import "default";
// Syntax highlighting @import is at the bottom of this file
  

/**************/
/* BASE RULES */
/**************/

.posts {
  overflow: scroll;
  height: 100%;

  @include mobile {
    padding: 0 15px;
  }
}

.page {
	max-width: 80%;
  @include mobile {
    padding: 15px;
  }
}

.svg-icon:hover {
  opacity: 75;
}

.entry img {
  max-width: 500px;
  display: block;
  margin: auto;
}

.post, 
.entry {
  font-family: $sans-serif;
  line-height: normal;
  @include mobile{
    padding: 0;
  }
}

a {
  color: $green;
  text-decoration: none;
	font-family: $serif;
	cursor: pointer;
  &:hover, &:active {
    color: $green-80;
  }
}

ul, ol {
	margin: 15px 0;
	padding-left: 30px;
	font-family: $sans-serif;
}

ul {
  list-style-type: disc;
}

ol {
  list-style-type: decimal;
}

ol ul, 
ul ol,
ul ul,
ol ol {
  margin: 0;
}

ul ul,
ol ul {
  list-style-type: circle;
}

em,
i {
  font-style: italic;
}

strong,
b {
  font-weight: 700;
}


// Fixes images in popup boxes from Google Translate
.gmnoprint img {
  max-width: none;
}

.date {
  font-style: italic;
  color: $bkg-white;
}

// Specify the color of the selection
::-moz-selection {
  color: $green;
  background: $blue;
}
::selection {
  color: $black;
  background: $blue;
}

// Nicolas Gallagher's micro clearfix hack
// http://nicolasgallagher.com/micro-clearfix-hack/
.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

/*********************/
/* LAYOUT / SECTIONS */
/*********************/

.main-text {
	max-width: 80%;
	margin: 0;
   @include mobile {
      margin: 0;
      width: 100%;
      position: relative;
    }
}

.posts > .post {
  padding-bottom: 2em;
  border-bottom: 1px solid $blue;
}

.posts > .post:last-child {
  padding-bottom: 1em;
  border-bottom: none;
}

.post {
  blockquote {
    margin: 1.8em .8em;
    border-left: 2px solid $darkGray;
    padding: 0.1em 1em;
    color: $darkGray;
    font-size: 22px;
    font-style: italic;
  }

  .comments {
    margin-top: 10px;
  }

  .read-more {
    text-transform: uppercase;
    font-size: 15px;
  }
}

.wrapper-footer {
    @include mobile {
    margin: 0;
    width: 100%;
    box-sizing: border-box;
  }
}

footer {
  padding: 60px 0;
}

// Settled on moving the import of syntax highlighting to the bottom of the CSS
// ... Otherwise it really bloats up the top of the CSS file and makes it difficult to find the start
@import "highlights";
@import "svg-icons";
@import "typography";
@import "flexbox";
@import "sections";
@import "nav";

@import "margin_padding";
