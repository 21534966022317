nav {
  box-sizing: border-box;
  ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    z-index: 100;

    li {
      list-style-type: none;
      padding: 0 1em;
    }
  }
}

.nav {
  padding: 0 2vw;
  font-family: $serif;
  font-size: 18px;
  padding: 0 20px;
  width: 100vw;

  @include mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 16px;
  }

  .nav-links {
    color: $green;
    font-weight: 500;
    font-family: $sans-serif;
    &:hover {
      color: $blue;
    }

    @include mobile {
      margin: 0 10px;
      color: $green;
    }
  }
}

.site-info {
  @include mobile {
    display: block;
    margin: 0 auto;
  }
}

.site-name {
  margin: 0;
  cursor: pointer;
  font-family: $serif;
  font-weight: 300;
  font-size: 28px;
  letter-spacing: 1px;
}

.site-description {
  @include mobile {
    margin: 3px 0;
  }
}
