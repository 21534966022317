// sizes
$breakpoint-sm: '(max-width: 40em)';
$breakpoint-md: '(min-width: 41em) and (max-width: 99.99em)';
$breakpoint-lg: '(min-width: 100em)';

body {
  color: $darkGray;
  background-color: $bkg-white;
  position: relative;
  margin: 0;
  padding: 0;
}

.container,
.section {
  margin: 0 auto;
  padding: 0;
  width: 85vw;
  max-width: 40rem;
  min-height: 100vh;
  &.full-width-bleed {
    max-width: 100vw;
    width: 100vw;
  }
}


.portfolio-section {
  // min-height: 25vh;
  max-width: 85vw;
  margin: auto;
}

.portfolio-section {
  max-width: 100vw;
}

.border-box {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.dark-blue {
  color: $dark-blue;
}
.light-blue {
  color: $blue;
}
.blue-gray {
  color: $lite-blue;
}
.blue {
  color: $green;
}

.profileImg {
  min-width: 150px;
  max-width: 150px;
  min-height: 150px;
  max-height: 150px;
  border-radius: 50%;
  background-color: $white
}

.absolute,
.absolute-center {
  position: absolute;
}

.fixed {
  position: fixed;
}

.relative {
  position: relative;
}

.hero {
  width: 100vw;
  display: block;
}
.absolute-center {
  top: 45%;
  left: 50%;
  transform: translate(-50%);
}

.hero-content {
  width: 100vw;
  height: 100vh;
}

.top-0 {
  top: 0;
}

.top-50 {
  top: 50%;
}

.left-0 {
  left: 0;
}

.right-0 {
  right: 0;
}

.bottom-0 {
  bottom: 0;
}

.relative {
  position: relative;
}
.absolute {
  position: absolute;
}
.sticky {
  position: sticky;
}

.footer-links {
  margin: 0 auto;
  .footer-link {
    padding: 0 0.5em;
  }
}