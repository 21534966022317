@import 'default';

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      width:100%;
}

.column {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}

.wrap {
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}

.items-center {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.items-start {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}

.items-end {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}

.align-self-end {
  -ms-flex-item-align: end;
      align-self: flex-end;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.justify-center {
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.justify-start {
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}

.justify-end {
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.justify-between {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}

.justify-around {
  -ms-flex-pack: distribute;
      justify-content: space-around;
}

@media #{$breakpoint-sm} {
  .sm-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .sm-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media #{$breakpoint-md} {
  .md-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .md-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}
@media #{$breakpoint-lg} {
  .lg-row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .lg-column {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
}

@media #{$breakpoint-md}{
  @for $i from 1 through 12 {
    .md-col-#{$i} {
      max-width: percentage($i / 12);
      width: percentage($i / 12);
      -ms-flex-preferred-size: percentage($i / 12);
          flex-basis: percentage($i / 12);
    }
  }
}