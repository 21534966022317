
//
// .main
//

.main {
	@include mobile {
    padding: 15px;
  }
}

.main-text {
	max-width: 80%;
	margin: 0;
   @include mobile {
      margin: 0;
      width: 100%;
      position: relative;
    }
}

.posts > .post {
  padding-bottom: 2em;
  border-bottom: 1px solid;
}

.posts > .post:last-child {
  padding-bottom: 1em;
  border-bottom: none;
}

.post {
  blockquote {
    margin: 1.8em .8em;
    border-left: 2px solid $darkGray;
    padding: 0.1em 1em;
    color: $darkGray;
    font-size: 22px;
    font-style: italic;
  }

  .comments {
    margin-top: 10px;
  }

  .read-more {
    text-transform: uppercase;
    font-size: 15px;
  }
}

.full-height {
  height: 95vh;
  &.full-width-circ {
    height: 95vh;
    max-height: 300px;
    max-width: 95vh;
  }
}


.col-1,
.width-100 {
  width: 100%;
}

.col-2 {
  width: 50%;
}

.col-3 {
  width: 33.33%;
}

.col-4 {
  width: 25%;
}

.portfolio-section {
  min-height: 75vh;
}

@media screen and (prefers-color-scheme: dark){
  .bkg {
    background-color: $blue;
  }
  .header {
    color: $white;
  }
}
@media screen and (prefers-color-scheme: light){
  .bkg {
    background-color: $white;
  }
  .header {
    color: $blue;
  }
}

.bkg-blue {
  background-color: $blue;
}
.bkg-blue-2 {
  background-color: $blue-2;
}
.bkg-green {
  background-color: $green;
}
.bkg-dark-blue {
  background-color: $dark-blue;
}
.bkg-lite-blue {
  background-color: $lite-blue;
}

// BORDER

.border {
  border: 1px solid;
}
.border-top {
  border-top: 1px solid;
}
.border-bottom {
  border-bottom: 1px solid;
}
.border-left {
  border-left: 1px solid;
}
.border-right {
  border-right: 1px solid;
}

// WIDTH

.full-width {
  width: 100%;
}

.placeholder {
  height: 100px;
  background-color: $slateGray;
  width: 100px;
}

.max-width {
  max-width: 768px;
  margin-left: auto;
  margin-right: auto;
}


// FOOTER

.footer {
    width: 100%;
}