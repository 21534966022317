$sizes: (0, 0.5rem, 1rem ,2rem, 4rem);
@each $size in $sizes {
  $i: (index($sizes, $size) - 1);
  .mt#{$i} {
    margin-top: $size;
  }
  .mr#{$i} {
    margin-right: $size;
  }
  .mb#{$i} {
    margin-bottom: $size;
  }
  .ml#{$i} {
    margin-left: $size;
  }
  .pt#{$i} {
    padding-top: $size;
  }
  .px#{$i} {
    padding-left: $size;
    padding-right: $size;
  }
  .py#{$i} {
    padding-top: $size;
    padding-bottom: $size;
  }
  .pr#{$i} {
    padding-right: $size;
  }
  .pb#{$i} {
    padding-bottom: $size;
  }
  .pl#{$i} {
    padding-left: $size;
  }
  .p#{$i} {
    padding: $size;
  }
  .m#{$i} {
    margin: $size;
  }
  .mx#{$i} {
    margin-left: $size;
    margin-right: $size;
  }
  .ml#{$i} {
    margin-left: $size;
  }
  .mr#{$i} {
    margin-right: $size;
  }
  .mt#{$i} {
    margin-top: $size;
  }
  .mb#{$i} {
    margin-bottom: $size;
  }
  .my#{$i} {
    margin-top: $size;
    margin-bottom: $size;
  }
}
@each $size in $sizes {
  $i: (index($sizes, $size) - 1);
  @media screen and (max-width: 41rem) {
    .xs-mt#{$i} {
      margin-top: $size;
    }

    .xs-mr#{$i} {
      margin-right: $size;
    }

    .xs-mb#{$i} {
      margin-bottom: $size;
    }

    .xs-ml#{$i} {
      margin-left: $size;
    }

    .xs-pt#{$i} {
      padding-top: $size;
    }

    .xs-px#{$i} {
      padding-left: $size;
      padding-right: $size;
    }

    .xs-py#{$i} {
      padding-top: $size;
      padding-bottom: $size;
    }

    .xs-pr#{$i} {
      padding-right: $size;
    }

    .xs-pb#{$i} {
      padding-bottom: $size;
    }

    .xs-pl#{$i} {
      padding-left: $size;
    }

    .xs-p#{$i} {
      padding: $size;
    }

    .xs-m#{$i} {
      margin: $size;
    }

    .xs-mx#{$i} {
      margin-left: $size;
      margin-right: $size;
    }

    .xs-ml#{$i} {
      margin-left: $size;
    }

    .xs-mr#{$i} {
      margin-right: $size;
    }

    .xs-mt#{$i} {
      margin-top: $size;
    }

    .xs-mb#{$i} {
      margin-bottom: $size;
    }

    .xs-my#{$i} {
      margin-top: $size;
      margin-bottom: $size;
    }
  }
}

.mt-auto {
  margin-top: auto;
}
.mb-auto {
  margin-bottom: auto;
}
.ml-auto {
  margin-left: auto;
}
.mr-auto {
  margin-right: auto;
}
.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}
.mx-auto {
  margin-right: auto;
  margin-left: auto;
}